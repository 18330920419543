import React from 'react';

import Incrementer from 'components/elements/Incrementer';
import QuantityPopover from '../QuantityPopover';

import { QuantitySelectorProps } from './props';

const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  onChange,
  cartItem,
  action,
  triggerButtonText,
  actionButtonText,
  modifyingItem,
  resetProduct,
  minValue = 0,
  sx,
  error,
  alert,
  disabled,
}) => {
  if (cartItem.unit?.seletor === 'PROJETO') {
    return (
      <QuantityPopover
        cartItem={cartItem}
        onChange={newValues => {
          onChange('projectValues', newValues);
        }}
        action={action}
        triggerButtonText={triggerButtonText}
        actionButtonText={actionButtonText}
        modifyingItem={modifyingItem}
        resetProduct={resetProduct}
        error={error}
        alert={alert}
      />
    );
  }

  const min = cartItem.unit?.referencias?.find(
    r => r.referencia === 'QTD_MINIMA',
  )?.valor;
  const max = cartItem.unit?.referencias?.find(
    r => r.referencia === 'QTD_MAXIMA',
  )?.valor;
  const increment = cartItem.unit?.referencias?.find(
    r => r.referencia === 'INCREMENTO',
  )?.valor;
  const key = `${cartItem.productName || 'product'}.${cartItem.productId}.${
    cartItem.unityId
  }.${cartItem.control}.${cartItem.quantity}`;

  return (
    <Incrementer
      key={cartItem.lastUpdated || key}
      name="quantity"
      size="small"
      value={cartItem.quantity}
      onChange={newValue => onChange('quantity', Number(newValue) || 0)}
      minValue={min || minValue}
      maxValue={max}
      increment={increment}
      updadteForMultiple={increment !== undefined}
      fullWidth
      sx={sx}
      disabled={disabled}
    />
  );
};

export default QuantitySelector;
