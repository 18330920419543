import { useCallback, useMemo } from 'react';

import {
  add,
  clear,
  addMultipleItems,
  increment,
  update,
  remove,
  setItem,
} from 'core/redux/slices/cart';
import { dispatch, useSelector } from 'core/redux/store';
import { CartType } from 'core/types/cart';
import { CartItem } from 'core/types/cartItem';

import { Variation } from 'core/models/productGrid';
import { useAuth } from './useAuth';
import useOrder from './useOrder';
import useServerCart from './useServerCart';

const useCart = (shoppingCartKey?: string) => {
  const { userIndentifier } = useAuth();

  const { orderConfig, cartKey: keyOrder, cartExists } = useOrder();

  const {
    newCart,
    changePreOrder: changePreOrderServer,
    clearCart: clearCartServer,
    clearCloseCart: clearCloseCartServer,
    addItem: addItemServer,
    updateItem: updateItemServer,
    removeItem: removeItemServer,
    isLoading: isLoadingServer,
  } = useServerCart(orderConfig?.id || '');
  const saveCart = orderConfig?.salvarCarrinho || false;
  const cartKey = keyOrder || shoppingCartKey || userIndentifier || '-';
  const cartState = useSelector(state => state.cart);

  const cart = cartState[cartKey];

  const items = useMemo(() => {
    return cart?.items || [];
  }, [cart?.items]);
  const { type } = cart || {};

  const addItem = useCallback(
    (cartItem: CartItem, actionType: 'ADD' | 'UPDATE' | 'INCREMENT') => {
      const {
        control,
        discountPercentage,
        discountValue,
        productId,
        quantity,
        unityId,
        price,
        productGrid,
      } = cartItem;

      const grid = productGrid?.variacoes.find(elem =>
        elem.variacoes.find(v => v.controle === control),
      );

      const variation = grid
        ? {
            id: grid.id,
            description: grid.descricao,
            variation: grid.variacoes.find(
              v => v.controle === control,
            ) as Variation,
          }
        : undefined;

      const index = items.findIndex(
        item =>
          item.productId === productId &&
          item.unityId === unityId &&
          item.control === control,
      );

      const key = `${productId}.${unityId}.${control}`;

      if (cartItem.projectValues) {
        dispatch(
          add({
            cartKey,
            cartItem: {
              ...cartItem,
              key: `${key}.Project:${Math.random().toString(16).slice(2)}`,
            },
          }),
        );

        return;
      }

      if (index >= 0 && !cartItem.projectValues) {
        if (actionType === 'INCREMENT') {
          dispatch(
            increment({
              cartKey,
              index,
              quantity,
              price,
            }),
          );
        } else {
          dispatch(
            update({
              cartKey,
              index,
              cartItem: {
                ...cartItem,
                key,
                variation,
                productGrid,
              },
            }),
          );
        }
      } else {
        dispatch(
          add({
            cartKey,
            cartItem: {
              ...cartItem,
              key,
              variation,
              productGrid,
            },
          }),
        );
      }
      if (saveCart) {
        if (cartExists) {
          if (actionType === 'INCREMENT') {
            addItemServer(cartItem);
          } else {
            updateItemServer(cartItem);
          }
        } else {
          newCart(cartItem);
        }
      }
    },
    [
      cartKey,
      items,
      saveCart,
      addItemServer,
      updateItemServer,
      newCart,
      cartExists,
    ],
  );

  const addManyItems = useCallback(
    (cartItems: CartItem[], cartType: CartType) => {
      const itemsToAdd: CartItem[] = [];

      cartItems.forEach(cartItem => {
        const { control, productId, unityId, productGrid } = cartItem;

        const key = `${productId}.${unityId}.${control}`;

        const grid = productGrid?.variacoes.find(elem =>
          elem.variacoes.find(v => v.controle === control),
        );

        const variation = grid
          ? {
              id: grid.id,
              description: grid.descricao,
              variation: grid.variacoes.find(
                v => v.controle === control,
              ) as Variation,
            }
          : undefined;

        itemsToAdd.push({
          ...cartItem,
          key,
          variation,
          productGrid,
        });
      });

      if (itemsToAdd.length > 0) {
        dispatch(
          addMultipleItems({
            cartKey,
            cartItems: itemsToAdd,
            type: cartType,
          }),
        );
      }
    },
    [cartKey],
  );

  const updateItem = useCallback(
    (index: number, cartItem: CartItem) => {
      dispatch(
        setItem({
          cartKey,
          index,
          cartItem,
        }),
      );
      if (saveCart) {
        updateItemServer(cartItem);
      }
    },
    [cartKey, saveCart, updateItemServer],
  );

  const findItem = useCallback(
    (key: string) => items.find(ci => ci.key === key),
    [items],
  );

  const findItemIndex = useCallback(
    (key: string) => items.findIndex(ci => ci.key === key),
    [items],
  );

  const removeItem = (index: number) => {
    dispatch(remove({ cartKey, cartItem: index }));
    if (saveCart && cartExists) {
      removeItemServer(items[index]);
    }
  };

  const removeByItem = (cartItem: CartItem) => {
    const { control, productId, unityId } = cartItem;

    const key = `${productId}.${unityId}.${control}`;
    const index = findItemIndex(key);

    dispatch(remove({ cartKey, cartItem: index }));
    if (saveCart && cartExists) {
      removeItemServer(items[index]);
    }
  };

  const clearCart = () => {
    dispatch(clear(cartKey));
    if (saveCart && cartExists) {
      clearCartServer();
    }
  };
  const clearCloseCart = () => {
    dispatch(clear(cartKey));
    if (saveCart && cartExists) {
      clearCloseCartServer();
    }
  };

  const changePreOrder = values => {
    dispatch(clear(cartKey));
    if (saveCart && cartExists) {
      changePreOrderServer(values);
    }
  };

  const changePartner = () => {
    dispatch(clear(cartKey));
  };

  const quantityItems = () => {
    return items.reduce((acc, curr) => acc + (curr.quantity || 0), 0);
  };

  const cartTotal = () =>
    items.reduce((acc, curr) => {
      return acc + curr.price.totalValue;
    }, 0);

  return {
    cartKey,
    type,
    items,
    addItem,
    removeItem,
    removeByItem,
    changePreOrder,
    changePartner,
    clearCart,
    clearCloseCart,
    quantityItems,
    updateItem,
    cartTotal,
    addManyItems,
    findItem,
    findItemIndex,
    isLoadingServer,
  };
};

export default useCart;
